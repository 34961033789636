$(".loader-wrapper").fadeOut("slow", function () {
    $(this).remove();
});
var toastElList = [].slice.call(document.querySelectorAll('.toast'))
var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl)
});


function toggleFullScreen() {
    if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
        if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(
                Element.ALLOW_KEYBOARD_INPUT
            );
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
}


//Email
function moForm() {
  document.getElementById("myForm").style.display = "block";
  document.getElementById("reply").style.display = "none";
}
/*Hàm Đóng Form*/
function dongForm() {
  document.getElementById("myForm").style.display = "none";
}

function toggleAddressCC() {
  const addressCCContainer = document.getElementById('addressCCContainer');
  if (addressCCContainer.style.display === 'none') {
    addressCCContainer.style.display = 'block';
  } else {
    addressCCContainer.style.display = 'none';
  }
}

function toggleAddressCC_1() {
  const addressCCContainer = document.getElementById('addressCCContainer_1');
  if (addressCCContainer.style.display === 'none') {
    addressCCContainer.style.display = 'block';
  } else {
    addressCCContainer.style.display = 'none';
  }
}


function moFormReply() {
  document.getElementById("reply").style.display = "block";
}
/*Hàm Đóng Form*/
function dongFormReply() {
  document.getElementById("reply").style.display = "none";
  document.getElementById("myForm").style.display = "none";
}


//End

